import styled from 'styled-components/macro';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';

interface IDiscountProps {
  addPadding: boolean;
}

export const Container = styled.div`
  margin-bottom: 60px;

  .cta {
    min-width: 100px;
  }
`;

export const Discount = styled(Span)<IDiscountProps>`
  padding: ${({ addPadding }) =>
    addPadding ? '10px 20px 8px 20px' : '10px 20px 0px 20px'};
  text-align: right;
  width: 100%;
  min-height: 28px;
  display: block;
  color: ${({ theme }) => theme.colors.secondary.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.button};
`;

export const AddOn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white.default};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.tinyBox};
  margin: 0 20px 10px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .close {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px 10px 0;
    margin-left: 20px;
    cursor: pointer;

    .errorIcon {
      margin-left: 5px;
      fill: ${({ theme }) => theme.colors.grey.faded};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.text.default};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      .errorIcon {
        margin-left: 5px;
        fill: ${({ theme }) => theme.colors.secondary.default};
      }
    }
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 60px;

  .title {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.large};
    margin-bottom: 15px;
  }

  .info {
    line-height: ${({ theme }) => theme.lineHeight.medium};
    color: ${({ theme }) => theme.colors.text.faded};
  }
`;

export const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colors.default.transparent};
  min-width: 300px;
  border-radius: 0 8px 8px 100px;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 30px 60px;

  .alignRight {
    display: flex;
    justify-content: flex-end;
  }

  .dollarIcon {
    vertical-align: super;
    font-size: ${({ theme }) => theme.fontSize.default};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.text.grey};
    padding-right: 3px;
  }

  .price {
    font-size: ${({ theme }) => theme.fontSize.heading};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.text.grey};
    margin-bottom: 20px;
  }

  .priceDescription {
    font-size: ${({ theme }) => theme.fontSize.button};
    color: ${({ theme }) => theme.colors.text.grey};
  }

  .CTA {
    margin-top: 30px;
    min-width: 100px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 60px 40px;
  width: 100%;

  .extraContentHeading {
    color: ${({ theme }) => theme.colors.text.default};
    font-size: ${({ theme }) => theme.fontSize.medium};
    margin-bottom: 5px;
  }

  .extraContentText {
    line-height: ${({ theme }) => theme.lineHeight.medium};
    color: ${({ theme }) => theme.colors.text.faded};
    margin-bottom: 30px;
  }
`;

export const Images = styled.div``;

export const Image = styled.img`
  margin-bottom: 10px;
`;
