import React, { useContext } from 'react';
import * as S from './AddOnsPage.styles';
import { addOnsArray, FAQs } from '../../../utils/helper/strings';
import { AngleBox, LayoutPage } from '../../shared/layout';
import { FAQ } from '../../shared/layout/faq';
import { H2, Span, H3 } from '../../shared/typefaces/Typefaces.styles';
import logo from '../../../images/logo-min.png';
import smoothie from '../../../images/smoothie-min.png';
import { Arrow } from '../../shared/icons';
import { LayoutContext } from '../../../contexts/layoutContext';
import { BillingCycle } from '../../../generated/graphql';
import { SelectAccountAddOn } from './sections/addOn';
import { SelectedAddOns } from './sections/selectedAddOns';
import { theme } from '../../../styles/theme';
import { Button } from '../../shared/button';
import { DotsLoading } from '../../shared/loading';
import { isFreeAccount } from '../../../utils/helper/subscription';

export const AddOnsPage = () => {
  const { appWidth, newAccount, dispatch } = useContext(LayoutContext);
  const freeAccount = isFreeAccount(newAccount?.type);

  const setBillingCycle = (billingCycle: BillingCycle) => {
    dispatch({
      type: 'SET_NEW_ACCOUNT',
      payload: { ...newAccount, billingCycle },
    });
  };

  const handleSeePlans = () => {
    dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
  };

  const closeEditAccountOverlay = () => {
    dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
  };

  return (
    <LayoutPage
      backButton
      backButtonCTA={closeEditAccountOverlay}
      withLine
      heading={'Update Account'}
      subHeading={'Update account to connect your team & refine your recipes'}
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button
                color="default"
                asCircle
                inversed
                onClick={closeEditAccountOverlay}
              >
                x
              </Button>,
            ]
          : [
              <Button
                color="default"
                inversed
                onClick={closeEditAccountOverlay}
              >
                Cancel
              </Button>,
              <Button
                onClick={handleSeePlans}
                color="secondary"
                disabled={false}
              >
                <DotsLoading
                  text={(loading) => `${loading ? 'Saving' : 'See Plans'}`}
                  isLoading={false}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>,
            ]
      }
    >
      <S.Container>
        <AngleBox color="default" marginTop="huge" />
        <S.Main marginTop="huge">
          <S.Content>
            <S.Header>
              <S.Left>
                <S.Heading>Add Ons</S.Heading>
                <H2 className="subHeading">
                  <Span className="subHeadingTop">
                    Upgrade Your Performance &
                  </Span>
                  <Span className="subHeadingBottom">
                    Streamline Your Workflow
                  </Span>
                </H2>
              </S.Left>
              <S.Logo className="logo" src={logo} />
            </S.Header>
            <S.Callout>
              <H2 className="heading">Add To Any Account, Anytime.</H2>
              <S.BreakLine />
              <Arrow color="grey" size="small" rotate={90} />
            </S.Callout>
            {!freeAccount && (
              <S.BillingCycle
                selected={newAccount?.billingCycle === BillingCycle.Yearly}
              >
                <S.Tab
                  selected={newAccount?.billingCycle === BillingCycle.Monthly}
                  className="tab"
                  onClick={() => setBillingCycle(BillingCycle.Monthly)}
                >
                  Monthly
                </S.Tab>
                <S.Tab
                  selected={newAccount?.billingCycle === BillingCycle.Yearly}
                  className="tab"
                  onClick={() => setBillingCycle(BillingCycle.Yearly)}
                >
                  Yearly
                </S.Tab>
              </S.BillingCycle>
            )}
            <S.AddOns>
              {addOnsArray.map((a) => (
                <SelectAccountAddOn addOn={a} />
              ))}
            </S.AddOns>
            <S.AccountHeader>
              <H3 className="accountHeading">See Fresh Profit Immediately</H3>
              <Span className="accountSubHeading">
                Upgrade Your Performance, Today
              </Span>
            </S.AccountHeader>
            <SelectedAddOns />
          </S.Content>
          <H3 className="questionsHeader">Further Questions</H3>
          <FAQ questions={FAQs} />
          <S.FooterImage>
            <img src={smoothie} alt="Smoothie" className="footerImage" />
          </S.FooterImage>
        </S.Main>
      </S.Container>
    </LayoutPage>
  );
};
